exports.components = {
  "component---src-layout-post-tsx": () => import("./../../../src/layout/post.tsx" /* webpackChunkName: "component---src-layout-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-project-tsx": () => import("./../../../src/pages/about/project.tsx" /* webpackChunkName: "component---src-pages-about-project-tsx" */),
  "component---src-pages-about-publish-tsx": () => import("./../../../src/pages/about/publish.tsx" /* webpackChunkName: "component---src-pages-about-publish-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-sponsor-tsx": () => import("./../../../src/pages/sponsor.tsx" /* webpackChunkName: "component---src-pages-sponsor-tsx" */)
}

